export default {
  routes: [
    {
      path: '/Paulgraham/contents',
      name: 'Paulgraham_Contents',
      component: () => import(/* webpackChunkName: "about" */ '@/views/Paulgraham/Contents.vue')
    },
    {
      path: '/Paulgraham/WriteSimply',
      name: 'Paulgraham_WriteSimply',
      component: () => import(/* webpackChunkName: "about" */ '@/views/Paulgraham/WriteSimply.vue')
    },
    {
      path: '/Paulgraham/Earnestness',
      name: 'Paulgraham_Earnestness',
      component: () => import(/* webpackChunkName: "about" */ '@/views/Paulgraham/Earnestness.vue')
    }
  ]
}