<template>
  <div class="home ml-16 mr-16 mt-5">
    <p>18 heures, en <Tooltip label="パリ郊外">banlieue parisienne</Tooltip>. Les yeux et le <Tooltip label="親指">pouce</Tooltip> sur son smartphone, 
    Cédric ressemble à beaucoup de Français de retour de travail.
    <Tooltip label="…を除いて">Sauf</Tooltip> qu'il n'est pas en train d'envoyer des SMS… Du <Tooltip label="先、端">bout</Tooltip> des doigts, cet homme <Tooltip label="操縦する">pilote</Tooltip> sa maison.<br>
    « Là, je dis à la maison que je rentre, donc les <Tooltip label="シャッター">volets</Tooltip> vont <Tooltip label="開く">s'ouvrir</Tooltip>. »<br>
    Chez lui, Cédric a <Tooltip label="つなぐ">relié</Tooltip> des <Tooltip label="10個">dizaines</Tooltip> d'objets à internet. 
    Il les contrôle à distance <Tooltip label="…のおかげで">gràce à</Tooltip> son téléphone.<br>
    « Qu'est-ce que vous pouvez contrôler d'autre <Tooltip label="[起点]…から">à partir de</Tooltip> votre téléphone ?<br>
    - Alors, à partir du téléphone, il va être possible de contrôler l'intégralité des lumières. »<br>
    Dans toutes les <Tooltip label="部屋">pièces</Tooltip> de la maison, des caméras de <Tooltip label="監視">surveillance</Tooltip>, elles aussi connectées.<br>
    « Imaginons que je ne sois pas à la maison. J'ai besoin de <Tooltip label="確認する">vérifier</Tooltip> pour une raison ou pour une autre <Tooltip label="様子、なにがおきているのか">ce qui se passe</Tooltip> dans la maison,
    j'ai un <Tooltip label="[情報]アクセス">accès</Tooltip> direct à l'<Tooltip label="全体">intégralité</Tooltip> des images prises par les caméras de la maision.
    Le chambre de mes filles, l'entrée, le garage, l'<Tooltip label="外観">extérieur</Tooltip>.<br>
    - <Tooltip label="たとえ…でも">Même si</Tooltip> vous être en vacances, au ski…<br>
    - <Tooltip label="[起点]その瞬間から">À partir du moment</Tooltip> où mon téléphone <Tooltip label="キャッチする">capte</Tooltip>, j'ai accès à la maison. »<br>
    Au moment d'aller se coucher, il <Tooltip label="[ボタンなどを]押す">appuie sur</Tooltip> « bonne nuit» : tout le <Tooltip label="棟、戸建ての家">pavillon</Tooltip> <Tooltip label="[状態に]なる">se met</Tooltip> en sommeil.</p>

    <p>Maison, mais aussi santé, <Tooltip label="家電">électroménager</Tooltip>…
    Des dizaines d'objets connectés sont déjà <Tooltip label="入手できる">disponibles</Tooltip> en France.
    Comme la fourchette connectée : elle vous alerte si vous mangez trop vite ou si vous ne <Tooltip label="[食べ物を]かむ">mâchez</Tooltip> pas assez.
    La raquette de tennis qui <Tooltip label="記録する">enregistre</Tooltip> les performances du joueur et les compare à Raphaël Nadal.
    Cette <Tooltip label="[幹が１本だけの]苗木">tige</Tooltip> plantée dans la terre : elle <Tooltip label="[幹が1本の]苗木">tige</Tooltip> <Tooltip label="通報する、連絡する、知らせる">prévient</Tooltip> quand les plantes ont besoin d'eau.
    Ou encore le réfrigérateur dit intelligent : il n'y a plus de lait. 
    Il peut aussi passer commande automatiquement sur le site d'un supermarché.</p>

    <p>En 2020, 80 <Tooltip label="10億">milliards</Tooltip> d'objets connectés <Tooltip label="…を増やす、…をいっぱいにする">peupleront</Tooltip> notre quotidien.
    Comme ces voitures, qui <Tooltip label="[車・列車が]走る">rouleront</Tooltip> sans conducteur.
    Comment fonctionnent ces objets d'un nouveau type ?
    Les <Tooltip label="ユーザー">utilisateurs</Tooltip> l'<Tooltip label="知らない">ignorent</Tooltip> souvent : tous ces <Tooltip label="装置">dispositifs</Tooltip> <Tooltip label="収集する">recueillent</Tooltip> des <Tooltip label="データ">données</Tooltip> sur leur <Tooltip label="プライバシー、私生活">vie privée</Tooltip>.
    Sont-elles bien <Tooltip label="保護する、守る">protégées</Tooltip> ?
    Aller simple pour un futur très proche.</p>

    <h2>2 <Tooltip label="[質問を]する">Posez</Tooltip>-vous les bonnes questions!</h2>
    Regardez la vidéo.
    <ol type="A">
    <li>Que fait Cédric à 18h avec son téléphone ?</li>
    <li>Quels objets de sa maison a-t-il connectés ?</li>
    <li>Quels autres objets connectés sont cités ? Expliquez leur usage.</li>
    <li>Que <Tooltip label="予想する">prévoit</Tooltip>-on pour le futur ? Quel possible danger est <Tooltip label="思い起こす、喚起する">évoqué</Tooltip> ?</li>
    </ol>
    <br>
    > Expliquer l'utilité et le fonctionnement d'un objet, p. 109
    <br>

    <h2>3 Restez à l'écoute !</h2>
    <ol type="A">
    <li>Quel est le « code » qui permet à Cédric de tout <Tooltip label="明かりを消す">éteindre</Tooltip> dans sa maison ?</li>
    <li>D'après le reportage, l'utilisation des objets connectés est-elle risquée ? Pourquoi ?</li>
    <li><Tooltip label="[実例を]上げる">Citez</Tooltip> un objet connecté qui sera bientôt commercializé.</li>
    </ol>

    <h2>4 <Tooltip label="[saisir]…を把握する、理解する">Saisissez</Tooltip> la grammaire!</h2>
    Retrouvez les phrases qui expriment:
    - un futur proche;
    - un futur lointain.
  
    <br><hr><br>

    <h2 class="orange--text text--darken-4">Le futur proche et le futur simple</h2>
    <span class="orange--text text--darken-4">Écoutez et relavez le défi.</span>
    <ol type="A">
      <li>Relevez les verbes au futur.</li>
      <li>Qu'exprime chaque verbe?</li>
      <li>Comment se forme le futur proche ? Et le futur simple ?</li>
    </ol>

    <h2 class="blue--text text--darken-4">58. Le futur proche et le futur simple</h2>
    - Dis. t'as vu la nouvell imprimante 3D ? Tu vas te l'acheter ?<br>
    - Je sais …<br>
    - Si tu l'achètes, tu me la <Tooltip label="[無料で人に]貸す">prêteras</Tooltip> ?<br>
    - On verra… <Tooltip label="確かなことは">Ce qui est sûr</Tooltip>, c'est que je ne vais pas l'acheter <Tooltip label="すぐに、ただちに">tout de suite</Tooltip> : 
    elle vient de <Tooltip label="発売される">sortir</Tooltip> et coûte très cher, t'as vu ?<br>
    -Quais…<br>
    - <Tooltip label="それから">Et puis</Tooltip>, c'est l'anniversaire d'Assma en juin.
    Elle <Tooltip label="祝う">fêtera</Tooltip> ses 30 ans. Alors, je vais <Tooltip label="とにかく、[d'abord A ensuite B]まずはじめにAそれからB">d'abord</Tooltip> <Tooltip label="節約する">économiser</Tooltip> pour ça et <Tooltip label="それから">ensuit</Tooltip>, <Tooltip label="まあ様子を見てみよう、今にわかるでしょう">on verra !</Tooltip>
    - Je pourrai venir ?
    - Ben, <Tooltip label="もちろん">évidemment</Tooltip> !

    <v-card>
      <v-card-text>
        <span class="orange--text text--darken-4">
          Le futur proche permet de situer une action dans un avenir immédiat.
        </span>
        Il est <Tooltip label="かなり">assez</Tooltip> fréqent à l'oral. <br>
        On l'utilise quand on estime qu'un <Tooltip label="出来事">événement</Tooltip> a plus de chances de se réaliser.<br>
        <span class="orange--text text--darken-4">
          Le futur simple:<br>
        </span>
        <ul>
          <li>situe l'action dans un avenir lointan :</li>
          <li>exprime un <Tooltip label="事柄">fait</Tooltip> <Tooltip label="計画する">programmé</Tooltip></li>
          <li>permet d'exprimer une demande.</li>
        </ul>
      </v-card-text>
    </v-card>

    <h3>
      1. Lisez ce <Tooltip label="ビラ、ちらし、パンフレット">tract</Tooltip> du « Parti des <Tooltip label="幸福な人">Bienheureux</Tooltip> » et conjuguez les verees au futur simple.
    </h3>
    Dimanche prochain, les élections municipales [<Tooltip label="[行事などが]行われる">avoir lieu</Tooltip>] …… Vous ne savez pas pour qui voter ? 
    Lisez notre programme, et vous [être] …… conquis.
    Nous [faire] …… tout pour répondre à vos demandes.
    Nous [prendre]…… le temps de vous écouter. 
    Nous [<Tooltip label="持ちこたえる">ttenir bon</Tooltip>] …… dans la <Tooltip label="嵐">tempête</Tooltip>. 
    Nous [savoir] …… vous <Tooltip label="[rendre A B]AをBにする">rendre</Tooltip> heureux !
    Ensemble, nous [conduire] …… la commune vers plus d'harmonie ! 
    Votez pour le PDB !

    <h3>
      2. Futur proche ou futur simple ? <Tooltip label="囲む">Entourez</Tooltip> la réponse la plus adaptée et justifiez.
    </h3>
    <ol>
      <li><Tooltip label="結果">Suite</Tooltip> à la manifestation d'hier, le gouvernement (va revenir - reviendra) sur sa décision.</li>
      <li>Pendant deux ans, les <Tooltip label="税">impôts</Tooltip> [ne vont pas augmenter - n'augmenteront pas].</li>
      <li>Ce soir, je [vais préparer - préparerai] ma <Tooltip label="声明">déclaration</Tooltip>  d'impôts. Tu peux m'aider ?</li>
      <li>Les écologistes ont alerté l'opinion : 
        bientôt, les ressources (vont manquer - manqueront) !
        Et dans cinquante ans, il (va être - sera) trop tard pour <Tooltip label="対処する">réagir</Tooltip>.
      </li>
      <li>Je suis <Tooltip label="説得する">persuadée</Tooltip> qu'il (va réussir - réussira) à mettre la <Tooltip label="改革">réforme</Tooltip> des impôts <Tooltip label="然るべき状態に">en place</Tooltip>.</li>
    </ol>

    <h3>
      Suite à un <Tooltip label="難破">naufrage</Tooltip>, vous <Tooltip label="接岸する">accostez</Tooltip> sur une île déserte.
      Avec les survivants, vous imaginez les actions à <Tooltip label="確立する">mettre en place</Tooltip> <Tooltip label="[…するために]">afin de</Tooltip> créer une nouvelle société,
      que vous voulez plus juste.
      Pensez aux actions les plus urgentes à <Tooltip label="やり遂げる">accomplir</Tooltip> <Tooltip label="それから">puis</Tooltip> aux actions à <Tooltip label="計画する">projeter</Tooltip> dans un avenir plus lointain.
    </h3>
    EXEMPLE: Nous allons <Tooltip label="探検する">explorer</Tooltip> l'île pour trouver de la nourriture. 
    Nous allons aussi <Tooltip label="耕す">labourer</Tooltip> des terrains. 
    L'année prochaine, nous pourrons ainsi cultiver des plantes.

    <hr>

    <h2>5 Tendez l'oreille !</h2>
    <ol type="a">
      <li>Dites si vous entendez un enchaînement vocalique, un enchaînement <Tooltip label="子音の">consonantique</Tooltip> ou une liaison entre les mots suivants.</li>
      <li>Entendez-vous [n] entre les <Tooltip label="音節">syllabes</Tooltip>  qui ont un enchaînement <Tooltip label="母音の">vocalique</Tooltip> ?</li>
    </ol>

    <ol type="a">
      <li><Tooltip label="[原因・理由]…のために、…ゆえに">pour</Tooltip> une raison ou pour une autre</li>
      <li><Tooltip label="[時間・空間]…から、…以後">à partir du</Tooltip> moment où mon téléphone <Tooltip label="とらえる、キャッチする">capte</Tooltip></li>
    </ol>

    <h2>6 <Tooltip label="対処する">Réagissez</Tooltip> !</h2>
    <Tooltip label="[配分、反復]ずつ、ごとに">Par</Tooltip>  deux, vous participez au <Tooltip label="フランスの伝統的な発明大会">Concours Lépine</Tooltip>.
    Vous vous rendez à la Foire de Paris pour présenter une découverte révolutionnaire liée à un ojet technologique.
    Présentez-la sur votre <Tooltip label="陳列品">stand</Tooltip> en <Tooltip label="…を詳細に述べる">détaillant</Tooltip> son utilité et son fonctionnement et répondez aux questions dez plus <Tooltip label="好奇心">curieux</Tooltip> !

    <v-card>
      <v-card-title>
        LE+INFO
      </v-card-title>
      <v-card-text>
        Le <span class="font-weight-bold">Concours Lépine</span> a été créé en 1901 <Tooltip label="[動作主]…によって">par</Tooltip> le <Tooltip label="警視総監">préfet de police</Tooltip> Lous Lépine.
        Cette exposition <Tooltip label="報いる">récompense</Tooltip> les créations d'inventeurs français et étrangers.
        Elle <Tooltip label="[行事]行われる">a lieu</Tooltip> chaque année à la <Tooltip label="パリ国際見本市">Foire de Paris</Tooltip>.
      </v-card-text>
    </v-card>

    <h2>7 Agissez !</h2>
    Vous faites partie d'une association qui défend l'utilisation du <Tooltip label="デジタルの">numérique</Tooltip> et des nouvelles technologies dans tous les domaines [à l'école, dans les lieux publics, dans les transports, etc.].
    <Tooltip label="起草する">Rédigez</Tooltip> un petit texte présentant vos actions et vos projets : <Tooltip label="[言葉で]描写する、叙述する、詳しく述べる">décrivez</Tooltip> le monde de demain en insistant sur les <Tooltip label="改良">améliorations</Tooltip> que vous <Tooltip label="もたらす">apporterez</Tooltip>.

    <h1 class="pink--text text--darken-4">En 3D, s'il vous plaît !</h1>
    <h2 class="grey--text text--darken-4">Dans 10 ans, l'impression 3D aura révolutionné notre quotidien !</h2>
    <p>Le professeur Tournesol l'avait «inventée » en 1972 (dans Tintin et le Lac aux requins), elle prend aujourd'hui vie, l'imprimante 3D !</p>

    <p><span class="font-weight-bold">Késako<a href="#footnote-1"><sup>1</sup></a> ?</span>
    L'imprimante 3D fonctionne un peu comme une imprimante clasique : un ordinateur lui envoie un <Tooltip label="ファイル">fichier</Tooltip> et l'<Tooltip label="プリンター">imprimante</Tooltip>… l'<Tooltip label="印刷する">imprime</Tooltip>.<br>
    <Tooltip label="…を除いて">Sauf</Tooltip> qu'il s'agit de fichiers 3D, la représantation en trois dimensions d'un objet.
    Pour expliquer son fonctionnement de manière simple : l'<Tooltip label="インク">encre</Tooltip> est remplacée par des particules de matière (plastique, céramique…).
    Puis l'imprimante réalise l'objet <Tooltip label="層">couche</Tooltip> par couche, pour obtenir un résultat en trois dimensions. {…}
    </p>
    <p><span class="font-weight-bold">Et dans dix ans ?</span>
    Le coût de ces imprimantes va drastiqument diminuer - il <Tooltip label="位置する">se situe</Tooltip> aujourd'hui aux <Tooltip label="[数値]…くらい、およそ…">alentours</Tooltip> des 10 000 € pour de l'<Tooltip label="エントリーレベル">entrée de gamme</Tooltip> professionnelle - et cette technologie va très vite <Tooltip label="改良される、改善される">se perfectionner</Tooltip>.<br>
    <Tooltip label="たとえば">Ansi</Tooltip>, les paticuliers pourront s'acheter ces imprimantes et imprimer eux-mêmes des objets directement depuis leur <Tooltip label="[新製品の]展示会">salon</Tooltip>.
    Imaginez : Mme X, équipée de sa toute <Tooltip label="最新の">dernière</Tooltip> imprimente 3D, <Tooltip label="[a envie de 名/不定詞]…が欲しい、…したい">a envie d</Tooltip>'acheter de nouvelles <Tooltip label="取り皿">assiettes</Tooltip>.
    Elle se connecte sur le site d'un magasin d'<Tooltip label="家具、調度品">ameublement</Tooltip>, trouve le modèle parfait, l'achète en ligne et cinq minutes <Tooltip label="後で、のちほど">plus tard</Tooltip>, ses assiettes sont imprimées et <Tooltip label="提供する">prêtes</Tooltip> à être utilisées.<br>
    {…} Nos objets ne coûteront donc plus que leur coût d'impression.
    Souvenez-vous des années 2000 : chaque année, les <Tooltip label="売上">ventes</Tooltip> de CD et DVD <Tooltip label="転げ落ちる">dégringolaient</Tooltip><a href="#footnote-2"><sup>2</sup></a>, les majors<a href="#footnote-3"><sup>3</sup></a> <Tooltip label="取り乱す">s'affolaient</Tooltip>, le web <Tooltip label="…している最中である">était en train de</Tooltip> <Tooltip label="殺す">tuer</Tooltip> l'industrie du disque et du cinéma !
    Qu'en sera-t-il quand les chaises de <Tooltip label="商標、ブランド">marque</Tooltip> pourront être imprimées illégalement ?
    Ou <Tooltip label="より悪い">pire</Tooltip>, quand de <Tooltip label="より良い">meilleures</Tooltip> chaises seront proposées, gratuitement, sur le net ? 
    Combien de secteurs seront touchés ?{…}<br>
    {…} Serait-ce les <Tooltip label="始まり、発端">prémices</Tooltip><a href="#footnote-4"><sup>4</sup></a> d'une nouvelle révolution industrielle ?
    </p>

    <p id="footnote-1"><span class="pink--text text--darken-4">1</span> Expression familièe venant de l'occitan et signifiant «qu'est-ce que c'est ? »</p>
    <p id="footnote-2"><span class="pink--text text--darken-4">2</span> Chutaient.</p>
    <p id="footnote-3"><span class="pink--text text--darken-4">3</span> Les sociétés de l'industrie du disque.</p>
    <p id="footnote-4"><span class="pink--text text--darken-4">4</span> Les débuts.</p>

    <h2>1 Quverz l' œil !</h2>
    Regardez la photo. Qu'est-ce que c'est ?

    <h2>2 Lisez et réagissez !</h2>
    <ol type="a">
      <li>Qu'est-ce qui différencie une impression 3D d'une impression normale ?</li>
      <li>Dans dix ans, quelles seront les caractéristiques de l'imprimante 3D ?</li>
      <li>Pourquoi l'auteur parle-t-il de « nouvell révolution industrielle » ?</li>
      <li>Quel avenir <Tooltip label="考察する">envisage</Tooltip>-t-il pour l'impression 3D ?
        Quelle <Tooltip label="[…を]比較">comparaison</Tooltip> fait-il ?
      </li>
      <li><Tooltip label="[人の見解、情報など]によれば">Selon</Tooltip> vous, peut-on tout <Tooltip label="印刷する">imprimer</Tooltip> ?</li>
    </ol>
    > Envisager l'avenir, p. 108

    <h2><Tooltip label="[…を]考慮する">Envisager</Tooltip> l'avnir</h2>
    <ul>
      <li>Dans un mois, dix ans, un siècle…</li>
      <li>Cette technologie va <Tooltip label="改良される">se perfectionner</Tooltip>.</li>
      <li>Les particuliers pourront + <span class="font-italic">inf</span>.</li>
      <li>Qu'en sera-t-il quand + <span class="font-italic">futur</span> ?</li>
      <li>Serait-ce les début / prémices de + <span class="font-italic">nom</span> ?</li>
    </ul>

    <h1 class="pink--text text--darken-4">Repenser l'économie, c'est capital !</h1>
    <h2>Thomas Piketty <Tooltip label="変革する、革新する">révolutionne</Tooltip> la pensée économique dominante</h2>
    <p><Tooltip label="ある人々">Certains</Tooltip> l'appellent le « gourou des inégalités ». D'autres le comparent déjà à Karl Marx <Tooltip label="あるいはまた">ou encore</Tooltip> à Adam Smith.
      Tous <Tooltip label="同意する">s'accordent</Tooltip> pour dire que son <Tooltip label="最新の">dernier</Tooltip> <Tooltip label="著作">ouvrage</Tooltip> <Tooltip label="示す">marque</Tooltip> une révolution dans la façon de penser les inégalités et la <Tooltip label="分配">répartition</Tooltip> des <Tooltip label="富">richesses</Tooltip>.
      Portrait de Thomas Piketty, <Tooltip label="卓越した">éminent</Tooltip> économiste français, au traverse de son dernier ouvrage, Le Capital au XXI<sup>e</sup> siècle.
      Une véritable bombe intellectuelle qui fait voler en éclat les illusions de la pensée <a href="https://ja.wikipedia.org/wiki/%E6%96%B0%E8%87%AA%E7%94%B1%E4%B8%BB%E7%BE%A9" target="_blank">néolibéral</a> dominante en Europe et aux États-Unis.
    </p>

    <h3><Tooltip label="相続人">Héritier</Tooltip> de Karl Marx</h3>
    <p>Quelle thèse <Tooltip label="[学説などを]支持する、主張する">défend</Tooltip> Piketty dans son ouvrage ?
      Se <Tooltip label="基礎を置く">basant</Tooltip> sur une profonde analyse du système économique dominant depuis le début du <Tooltip label="20">XX</Tooltip><sup>e</sup> siècle et de son <Tooltip label="避けられれない">inévitable</Tooltip> <Tooltip label="絡み合い、錯綜">imbrication</Tooltip> avec les idéologies politiques et sociales, il <Tooltip label="挑戦する">met au défi</Tooltip> les gouvernements démocratiques de résoudre les problèmes <Tooltip label="[問題を]提起する">posés</Tooltip> par un <Tooltip label="溝、断絶">fossé</Tooltip> des inégalités qui <Tooltip label="[溝などが]広がるしかない">ne fait que se creuser</Tooltip>.
      La <Tooltip label="新しさ">nouveauté</Tooltip> de la pensée de Piketty est d'expliquer que la réduction des inégalités qui a pu être observée <Tooltip label="[…]の間中、[期間]を通じてずっと">au course du XX</Tooltip><sup>e</sup> siècle n'est qu'une illusion due aux catastrophes historiques que représentent les deux guerres mondiales.
      […] Nous sommes aujourd'hui de retrour dans un <Tooltip label="概要">schéma</Tooltip> de <Tooltip label="成長、発展、増大">croissance</Tooltip> qui profite aux plus riches et les suppositions des années d'après-guerre selon lesquelles les inégalités iraient en se réduisant <Tooltip label="[apparaître comme]〜のように思われる、感じられる">apparaissent</Tooltip> aujourd'hui comme une vaste illusion.[…]
    </p>

    <h3>Croissance économique versus eroissance de capital</h3>
    <p>Dans son effort pour <Tooltip label="理解する">comprendre</Tooltip> les économies <Tooltip label="西洋の">occidentales</Tooltip>, Pikettly n'a peur ni de <Tooltip label="遡る">remonter</Tooltip> loin dans le temps ni de se baser sur des exemples <Tooltip label="[例などを]取り出す">puisés</Tooltip> dans la littérature réaliste du <Tooltip label="19">XIX</Tooltip><sup>e</sup> siècle.
      Balzac et Jane Austen lui servernt d'inspiration pour montrer qu'à l'époque il était <Tooltip label="一般に">communément</Tooltip> <Tooltip label="容認された">admis</Tooltip> que le travail n'était pas une <Tooltip label="構成要素">composante</Tooltip> <Tooltip label="十分な">suffisante</Tooltip> pour s'<Tooltip label="保証する">assurer</Tooltip> une vie confortable : <Tooltip label="唯一">seuls</Tooltip> l'héritage <Tooltip label="世襲財産の">patrimonial</Tooltip> et le capital étaient en mesure de la <Tooltip label="保証">garantir</Tooltip>.
      Pour lui, cette <Tooltip label="現実">réalité</Tooltip> n'a pas changé aujourd'hui.
    </p>
    <p>Pour Thomas Pikketty, les inégalités ne sont <Tooltip label="にもかかわらず">néanmoins</Tooltip> pas un <Tooltip label="障害">obstacle</Tooltip> fondamental au bon fonctionnement d'une société. Mais <Tooltip label="[〜]であるのに">lorsqu</Tooltip>'un système voit sa <Tooltip label="曲線">courbe</Tooltip> de croissance économique suivre une évolution normale, voire <Tooltip label="骨の折れる">laborieuse</Tooltip> , <Tooltip label="[〜]であるのに">tandis que</Tooltip> celle du Capital atteint des <Tooltip label="高騰する">envolées</Tooltip> exponentielles<a href="#footnote-2-1"><sup class="pink--text text--darken-4">1</sup></a>, il y a mis en danger de la démocratie et de la justice sociale.
      Les inégalités ne servent plus l'intérêt commun, mais seulement les intérêts privés d'une petite <Tooltip label="共同体">communauté</Tooltip> de privilégiés.
    </p>

    <h3>Une ouvrage <Tooltip label="公正な">impartial</Tooltip> et révolutionnaire</h3>
    <p>[…] <Tooltip label="にもかかわらず">Bien que</Tooltip> les solutions <Tooltip label="もたらず">apportées</Tooltip> par Piketty - essentiellement une <Tooltip label="課税">impossition</Tooltip> massive et <Tooltip label="精力的な">courageuse</Tooltip> sur la richesse <Tooltip label="現実の">réelle</Tooltip>, <Tooltip label="実行する">mise en place</Tooltip> de manière progressive - soient jugées par beaucoup politiquenment naïves, il semble bien ｑue son livre ait déjà sa place sur l'<Tooltip label="棚">étagère</Tooltip> des <Tooltip label="概論">traités</Tooltip> d'économie les plus <Tooltip label="明白">significatifs</Tooltip>. 
      Il continuerra d'influencer penseurs et politiques dans la construction de systèmes plus justes et viables<a href="#footnote-2-2"><sup class="pink--text text--darken-4">2</sup></a> dans les années, <Tooltip label="10年間">décennies</Tooltip>, peut-être même siècles à venir.</p>

    <p id="footnote-2-1"><sup class="pink--text text--darken-4">1</sup> Dont la croissance est très rapide et continue. </p>
    <p id="footnote-2-2"><sup class="pink--text text--darken-4">2</sup> Qui peuvent durer. </p>

    <h2>2 Posez-vous les bonnes question !</h2>
    <ol type="a">
      <li>Qui est Thomas Piketty ? Pourquoi parle-t-on de lui ?</li>
      <li>Quels <Tooltip label="一節">passages</Tooltip> <Tooltip label="一致する">correspondent</Tooltip> aux titres suivants ?</li>
      <ol type="1">
        <li>Approche historique de l'´conomie</li>
        <li>Publication d'un livre révolutionnaire</li>
        <li>Présentation générale de la position de Piketty</li>
        <li>Un ouvrage promis à un bel avenir</li>
        <li>Une Thèse paradoxale</li>
      </ol>
      <li>Quelle est la théorie de Piketty sur les inégalités ?</li>
      <li>Quels mots permettent de caractériser ce livre ?</li>
    </ol>

    <h2>3 Explorez le lexique !</h2>
    <ol type="a">
      <li><Tooltip label="見つける">Relevez</Tooltip> dans le texte les mots de l'´conomie.</li>
      <li>Retrouvez les termes correspondant à ces définitions :</li>
      <ol type="1">
        <li>Système et idéologie économique <Tooltip label="批判された">critiqués</Tooltip> par Piketty : le système ………</li>
        <li>Grande inégalité : ………</li>
        <li>Les deux éléments qui, hier comme aujourd'hui, selon Piketty, assurent une <Tooltip label="ゆとり">aisance</Tooltip> matérielle : ………</li>
        <li>Les riches : ………</li>
        <li>L'<Tooltip label="税金">impôt</Tooltip> : ………</li>
      </ol>
      <li>Quel signe de ponctuation [autre que les virgules] est utilisé pour <Tooltip label="加える">ajouter</Tooltip> une <Tooltip label="正確さ">précision</Tooltip> sur les idées de Piketty ?</li>
    </ol>

    <h2>4 <Tooltip label="saisir:把握する、理解する">Saisissez</Tooltip> la grammaire !</h2>
    <P>Dans la « une thèse paradoxale », <Tooltip label="見つける、指摘する">relevez</Tooltip> les mots qui indiquent:</P>
    <ul>
      <li>des <Tooltip label="反対">oppositions</Tooltip> dans les faits;</li>
      <li>une <Tooltip label="反論、矛盾">contradiction</Tooltip> apparente dans la position de Thomas Piketty.</li>
    </ul>

    <h2>5. Argumentez !</h2>
    <P>Libéralisme, socialisme, communisme… Dites en 150 mots <Tooltip label="およそ">envion</Tooltip> à quel système économique vous êtes le plus opposé.
     <Tooltip label="強調する、力説する">Soulignez</Tooltip> ses <Tooltip label="一貫性のなさ、支離滅裂">incohérences</Tooltip> et ses paradoxes à travers des examples.</P>
    <p class="grey--text text--darken-4">Exemple : Même si j'ai envie de bien gagner ma vie, je suis <Tooltip label="憤慨する">indigné</Tooltip> par le système capitaliste que je trouve injuste.
    Malgré leurs belles paroles, les socialistes ne cherchent eux aussi qu'à s'enrichir…</p>

    <h1 class="pink--text text--darken-4">La libération en pantalon</h1>
    <p>Gabrielle Bonheur Chanel, <Tooltip label="世界的に知られている">mondialement connue</Tooltip> sous le <Tooltip label="あだ名">surnom</Tooltip> Coco Chanel, est non seulement une grande créatrice de mode, mais c'est aussi et surtout une <Tooltip label="開放者">libératrice</Tooltip>.
    La petite Gabrielle apprend le <Tooltip label="職業">métier</Tooltip> de <Tooltip label="縫製工">couseuse</Tooltip> dès <Tooltip label="青年">sa majorité</Tooltip>, <Tooltip label="初め">au début</Tooltip> du xx<sup>e</sup> siècle.
    Une <Tooltip label="婦人帽製造販売業者、モード店主">modiste</Tooltip> <Tooltip label="引き受ける、担当する">se charge</Tooltip> de son initiation jusqu'à ce que Coco <Tooltip label="実現する、達成する">réalise</Tooltip> seule ses premières créations : des petits chapeaux qui <Tooltip label="額に垂れる">descendent sur le front</Tooltip>.
    <Tooltip label="すぐに">Peu de temps</Tooltip> apès avoir rencontré son premier amoureux, Balsan, elle ouvre avec son aide sa première boutique à Paris, boulevard Malesherbes.
    Ses chapeaux, sans <Tooltip label="羽根">plumes</Tooltip> ni <Tooltip label="飾りの多い女性敵な服">froufrous</Tooltip>, ressemblent à <Tooltip label="…の人々のもの">ceux</Tooltip> des hommes : ce <Tooltip label="歩み寄り">rapprochement</Tooltip> entre la mode féminine et la mode masculine est une première étape ver plus d'égalité.
    <Tooltip label="しばらくたってから">Quelques temps plus tard</Tooltip>, c'est avec un autre amoureux, l'Anglais Boy Capel, qu'elle ouvre sa deuxième boutique dans la très chic <Tooltip label="リゾート">station</Tooltip> <Tooltip label="海水浴">balnéaire</Tooltip> de Deauville.
    Et c'est le succès !
    Puis à Biarritz.
    Grand succès !
    Mais c'est au moment où elle ouvre sa boutique à Paris, <Tooltip label="すぐ近く">tout près de</Tooltip> la place Vendôme, qu'elle connaît un véritable triomphe !</p>
    <p>Bien entendu, une si belle réussite était exceptionnelle à l'époque pour une femme.
    Mais ce n'est pas uniquement pour cette raison que CoCo Chanel est une <Tooltip label="リーダー">figure</Tooltip> de féminisme.
    Son <Tooltip label="寄与、貢献">apport</Tooltip> dans la lutte pour l'égalité des femmes et des hommes est sourtout visible dans sa création et dans ses inventions.
    <Tooltip label="というのは">Car</Tooltip> elle a causé une véritable révolution <Tooltip label="衣服の">vestimentaire</Tooltip> pour les femmes.
    Comment ?
    <Tooltip label="短くする[ジェロンディフ]">En Raccourcissant</Tooltip> les jupes et les cheveux, et toutes ces longueur qui distinguaient l'homme et la femme depuis la Révolution.
    Elle <Tooltip label="取り除く、取りやめる">supprime</Tooltip> aussi les vêtements <Tooltip label="拘束">contraignants</Tooltip> pour la femme, comme le corset.
    Elle <Tooltip label="解放する">libère</Tooltip> la <Tooltip label="サイズ">taille</Tooltip>.
    Et elle va encore plus loin dans la masculinité en inventant le pantalon.
    <Tooltip label="???">Rendez-vous compte</Tooltip> !
    Une femme en pantalon !
    Comme un homme !</p>
    <p>Est-ce que la femme est égale à l'homme ?
    Quelles sont leurs différences ?
    Ce débat est celuis des philosophes et des biologistes…
    Ce qui importe à Coco Chanel - et à nous ! -, c'est que la femme s'habille comme elle veut !
    Merci Coco !</p>

    <h2>1 Écoutez !</h2>
    Qui parle et de qoui ? De quel type de document <Tooltip label="取り上げる、主題にする">s'agit</Tooltip>-il ?

    <h2>2 Posez-vous les bonnes questions !</h2>
    <ol type="a">
      <li>Qui était Coco Chanel ? Quand a-t-elle <Tooltip label="vivre[現在分子]">vécu</Tooltip> ?</li>
      <li>Où a-t-elle ouvert ses première boutiques ? Comment ont-elles été <Tooltip label="受け入れる、受け止める">accueillies</Tooltip> ?</li>
      <li>En quoi Coco Chanel a-t-elle modifié en profondeur la mode féminine ?</li>
      <li><Tooltip label="…によれば">Selon</Tooltip> la journaliste, qu'a apporté CoCo Chanel aux femmes ?</li>
    </ol>

    <h2>3 Explorez le lexique !</h2>
    <ol type="a">
      <li>Comment s'habillaient les femmes avant et après CoCo Chanel ? <Tooltip label="指摘する">Relevez</Tooltip> les mots et expressions qui <Tooltip label="思い起こさせる">évoquent</Tooltip> la mode et la création.</li>
      <li>Selon la journaliste, pourquoi Coco Chanel est-elle féministe ?</li>
      <li>Quelles phrases sont <Tooltip label="[言葉などを]かける">adressées</Tooltip> à l'<Tooltip label="聴衆">auditeur</Tooltip> ? Laquelle permet à la journaliste d'<Tooltip label="惹きつける、魅了する">attirer</Tooltip> son attention ?</li>
      <li>Quelle expression la journaliste utilise-t-elle pour annoncer sa conclusion ?</li>
    </ol>

    <h2>4 Saisissez la grammaire !</h2>
    <ol type="a">
      <li>Réécoutez et répondez aux questions en reprenant les mots exacts du document.</li>
        <ul>
          <li>Pendant combien de temps Coco Chanel a-t-elle appris le travail avec une modiste ?</li>
          <li>Quand ouvre-t-elle sa première boutique ?</li>
          <li>Quand connaît-elle son plus grand succès ?</li>
        </ul>
      <li>Quelles actions se suivent ? Quelles actions se passent en même temps ?</li>
      <li></li>
    </ol>
    <p class="orange--text text--darken-4">La simultanéité, l'antériorité, la postériorité, activités p. 101</p>

    <v-card>
      <v-card-title class="pink--text text--darken-4">
        <v-spacer/>ACTIVITÉ ÉTAPE
      </v-card-title>
      <v-card-text>
        <h2 class="pink--text text--darken-4">Réaliser une publicité pour un vêtement du futur</h2>
        <ul>
          <li>À trois, imaginez un vêtement futuriste qui pourrait changer votre vie.</li> Example : un vêtement éphémère et écologique qui s'auto-détruit à la fin de la journée.
          <li>À l'écrit, imaginez les changements et avantages que chet habit pourrait apporter dans la vie quotidienne.</li>
          <li>Préparez un dialogue entre trois amis : l'un porte déjà le vêtement et en vante les qualités ;
            l'autre est hésitant ; le dernier est enthousiaste et pose des questions sur les changements que perment ce vêtement au quotidien.
            La deuxième personne finit par être aussi enthousiaste.
          </li>
          <li>Présentez votre publicité devant la classe.</li>
        </ul>
      </v-card-text>
    </v-card>

    <h2 class="blue--text text--darken-4 mt-5">61. Activités 1 et 2 p. 102</h2>
    - Vos allez à quel étage ?<br>
    - Euh…<br>
    - Au 26<sup>e</sup>.<br>
    - Mais qu'est-ce qui se passe ?<br>
    - Ah bah ça ! Il m'est arrivéla même chose ce matin dans le métro… Les <Tooltip label="故障">pannes</Tooltip> sont fréquentes à Paris !<br>
    - C'est <Tooltip label="奇妙な">étrange</Tooltip>, <Tooltip label="[憤慨・強調]まったく、それにしても">quand même</Tooltip>, parce que chez nous, les ascenseurs sont <Tooltip label="結ぶ、つなぐ">reliés</Tooltip> à un réseau <Tooltip label="内部の">interne</Tooltip> ultra-sécurisé. 
    Mais <Tooltip label="この間から">depuis quelque temps</Tooltip>, il y a justement eu des <Tooltip label="[悪い]試み、企て">tentatives</Tooltip> de piratate…
    Je le sais, car c'est moi l'informaticien <Tooltip label="委ねられた">chargé</Tooltip> de la sécurité de…<br>
    - Chut ! <Tooltip label="口を閉ざす">Taisez</Tooltip>-vous ! Écoutez <br>
    - Au secours ! On est <Tooltip label="閉じ込める">enfermés</Tooltip> ! Faites-nous sortir ! Au secours !<br>
    - Lucas du calme ! Il n'y a pas de raison de paniquer ! Ce n'est pas si grave.<br>
    - Pas si grave ?!<br>
    - S'il te plaît ! <Tooltip label="[真剣な検討の前]どれどれ、さてさて">Voyons voir</Tooltip>… Analysons calmement la situation : nous sommes <Tooltip label="[押し付けて]動かなくする、固定する">coincés</Tooltip> dans un ascenseur, <Tooltip label="それだけ">voilà tout</Tooltip>.
    Une situation assez <Tooltip label="平凡な">banale</Tooltip>, en fait !
    <Tooltip label="怖がらないで">N'aie pas peur</Tooltip>, <Tooltip label="なんとかなる">ça va s'arranger</Tooltip> !<br>
    - Mais <Tooltip label="馬鹿げたことを言わないで">arrête de dire n'importe quoi</Tooltip> !
    Si on analyse les faits, <Tooltip label="明確なとおり">on voit bien que</Tooltip> nous sommes coincés au 15<sup>e</sup> étage d'une tour où il y a peut-être un <Tooltip label="火事">incendie</Tooltip> et nous allons tous…<br>
    - <Tooltip label="お話し中失礼ですが">Excusez-moi de vous interrompre</Tooltip> : <Tooltip label="[…する]必要はない">ce n'est pas la peine de</Tooltip> <Tooltip label="いらだつ">s'énerver</Tooltip>. 
    <Tooltip label="熟考する">Réfléchissons</Tooltip> un peu…<br>
    - Il faut <Tooltip label="作動させる">déclencher</Tooltip> l'alarme. Voilà, c'est fait !<br>
    - Oui, mais avec l'alarme incendie, c'est complètement inutile : personne ne va l'entendre, il y a déjà trop de bruit. Mieux vaut l'arrêter.<br>
    - Peu-être qu'on pourrait utiliser la ligne de secours ?<br>
    - Impossible : elle est coupée aussi. Je l'avais bien dit qu'une ligne comme ça, ça ne servait à rien, mais bien entendu la direction ne veut jamais rien entendre quand…<br>
    - Mais ce n'est pas le moment de parler de ça ! Comment fait-on pour sortir ? Je veux sortir ! À l'aide ! Nous sommes coincés !<br>
    - Pourquoi ne pas simplement utiliser nos portables ?<br>
    - Bonne idée ! Flûte ! Évidemment, ici, le résau ne pass pas !<br>
    - Et si on essayait d'ouvrir les portes de force ?<br>
    - Je crois que j'aurais dû passer moins de temps au restaurant et plus de temps à la salle de gym ! Aidez-moi, messieurs !<br>
    - Vous savez, je ne suis plus tout jeune… Je pars à la <Tooltip label="引退">retraite</Tooltip> cette année !<br>
    - Mais <Tooltip label="放っておけ、やめておけ、あきらめろ">laissez tomber</Tooltip> : vous n'allez jamais y arriver ! Même avec mon aide ! C'sst impossible à ouvrir !<br>
    - On pourrait alors essayer de sortir par en haut ? 
    <Tooltip label="[…]してください">Faites-moi</Tooltip> la courte échelle !<br>
    - <Tooltip label="お勧めしません">Je ne vous le conseille pas</Tooltip>. Vous risqueriez d'être <Tooltip label="感電死させる">électrocutée</Tooltip>..<br>
    - Ah ! Dans ce cas alors, je vais <Tooltip label="慎む">éviter</Tooltip>… Mais il reste peut-être une autre solution.<br>
    - Laquelle ?<br>
    - <Tooltip label="再稼働させる">Débloquer</Tooltip> l'ascenseur ! Je viens de <Tooltip label="気づく">m'apercevoir</Tooltip> que j'avais sans <Tooltip label="故意に">faire exprès</Tooltip> <Tooltip label="始動させる">enclenché</Tooltip> le bouton « stop »…

    <h1 class="purple--text text--darken-4"><Tooltip label="対処する、乗り切る">Gérer</Tooltip> une situation de crise</h1>

    <h2>1 <Tooltip label="対処する、対応する">Réagissez</Tooltip> !</h2>
    <ol type="a">
      <li>Observez l'image. Que voyez-vous ?<br>
      Imaginez ce qui se passe. Quels sentiments cette situation vous inspire-t-elle ?
      </li>
      <li>Écoutez le document. <Tooltip label="何人">Combien y a-t-il</Tooltip> de personnes ? Comment réagissent-elles ?</li>
      <li>Quelles solutions sont proposées pour <Tooltip label="解決する">résoudre</Tooltip> le problème ? Sont-elles <Tooltip label="効果がある">efficaces</Tooltip> ?</li>
    </ol>

    <h2>2 C'est dans la <Tooltip label="箱">boîte</Tooltip> !</h2>
    Écoutez de nouveau et <Tooltip label="仕上げる">complétez</Tooltip> la boîte à outils avec les exemples du document.

    <v-card>
      <v-card-title class="purple--text text--darken-4">
        <v-spacer/>BOIÎTE À OUTILS
      </v-card-title>
      <v-card-text>
        <h2 class="purple--text text--darken-4">Gérer une situation de crise</h2>
        <p class="purple--text text--darken-4">><Tooltip label="問題と向き合う">Faire face au problème</Tooltip></p>
        <ul>
          <li><Tooltip label="[問題などに]言及する">Évoquer</Tooltip> un probléme: …</li>
          <li>Réagir en exprimant ses sentiments:
            <ul style="list-style: none;">
              <li>la peur : …</li>
              <li>le <Tooltip label="冷静">sang-froid</Tooltip> : …</li>
            </ul>
          </li>
          <li>Analyser la situation : …</li>
        </ul>
        <p class="purple--text text--darken-4">>Résoudre le problème</p>
        <ul>
          <li><Tooltip label="求める">Demander</Tooltip> de l'aide : …</li>
          <li>Proposer une solution : …</li>
          <li>Répondre à une <Tooltip label="提案">proposition</Tooltip> : …</li>
        </ul>
      </v-card-text>
    </v-card>

    <h2>3 Du tac au tac !</h2>
    Formez des groupes et <Tooltip label="列にならぶ">mettez-vous en ligne</Tooltip>. 
    Dans chaque groupe, <Tooltip label="次々に現れる">défilez</Tooltip> <Tooltip label="１人づつ、次々に">les uns après les autres</Tooltip> devant la première personne de la ligne en lui donnant une situation problématique.<br>
    Elle <Tooltip label="反応する">réagit</Tooltip> « du tac au tac » en proposant une solution.<br>
    <Tooltip label="役割を交換する">Inversez les rôles</Tooltip> et <Tooltip label="考え出す、でっち上げる">inventez</Tooltip> d'autres situations.<br>

    <h2 class="blue--text text--darken-4 mt-5">62. Activité 3 et Activité 4 «Prononcez» p. 103</h2>
    <ol type="a">
      <li>Oh la la ! Je <Tooltip label="見つからない">ne trouve plus</Tooltip> les clés de la maison… Je vais être très en retard !</li>
      <li>Mon Dieu ! <Tooltip label="私が何をしたというのか">Qu'est-ce que j'ai fait ?</Tooltip> Le <Tooltip label="ファイル">fichier</Tooltip> sur lequel je travaillais a disparu de l'<Tooltip label="スクリーン">écran</Tooltip> !</li>
      <li>Oh nooon ! Mon costume est <Tooltip label="染みをつける">taché</Tooltip> et j'ai une réunion importante aujourd'hui… <Tooltip label="どうすればいい">comment je vais faire ?</Tooltip></li>
      <li><Tooltip label="ちぇっ">Zut</Tooltip> ! C'est l'anniversaire de Léon aujourd'hui, j'ai complètement oublié de lui acheter un cadeau…</li>
    </ol>

    <h2 class="blue--text text--darken-4 mt-5">63. Activité 4 «<Tooltip label="見つける">Repérez</Tooltip>» p. 103</h2>
    <ol type="a">
      <li><Tooltip label="大した事じゃない">Pas si grave</Tooltip> ! Mais arrête de dire <Tooltip label="馬鹿な事、ナンセンスな事">n'importe quoi</Tooltip> !</li>
      <li>une situation assez <Tooltip label="平凡">banale</Tooltip> en fait même avec mon aide</li>
      <li>C'est complètement inutile : personne ne va l'entendre.</li>
    </ol>

    <h2 class="blue--text text--darken-4 mt-5">64. Activité 4 «Mettez-y le <Tooltip label="トーン">ton</Tooltip>» p. 103</h2>
    - T'as vu ? Ils ont  annoncé qu'ils fermaient le <Tooltip label="学食">resto U</Tooltip> cette année. <Tooltip label="おそらく">Apparemment</Tooltip>, c'etait pas assez <Tooltip label="儲かる">rentable</Tooltip>…<br>
    - Assez rentable ?! Non mais n'importe quoi ! Et comment les étudiants vont faire maintentant ?! Les restos du quartier sont <Tooltip label="３倍">trois fois</Tooltip> trop chers pour nous ! Ah, <Tooltip label="むかつく">ça me met hors de moi</Tooltip> ! Je vais en parler aux <Tooltip label="自治会">syndicates</Tooltip> étudiants !

    <v-card>
      <v-card-title class="purple--text text--darken-4">
        LE+INFO
      </v-card-title>
      <v-card-text>
        <h2 class="purple--text text--darken-4"><Tooltip label="安心させる、落ち着かせる">RASSURER</Tooltip></h2>
        <ul>
          <li>C'est / Ce n'est pas grave…</li>
          <li>Ce n'est rien !</li>
          <li>Rassurez-vous !</li>
          <li>Ne t'inquiète pas. / T'inquiète !</li>
          <li>N'aie pas peur</li>
        </ul>
      </v-card-text>
    </v-card>

    <h2>4 Le son et le ton qu'il faut !</h2>
    <h3>① Repérez !</h3>
    Réécoutez ces extrait.
    <ol type="a">
      <li>Qui est en colère ? Comment se manifeste cette émotion dans son intonation ?</li>
      <li>Quels types d'enchaînements entendez-vous<br>
      entre les mots suivants ? Entendez-vous [n]<br>
      entre les syllabes <Tooltip label="関係する">concernées</Tooltip> ?</li>
      <li>Dites si vous entendez [ɔ̃], ɔ].</li>
    </ol>

    <h3>② Prononcez !</h3>
    Réécoutez les phrases. Le plus rapidement possible, retrouvez le son [ɔ̃] dans deux prises de parole.
    <v-card>
      <v-card-text  class="blue--text text--darken-4">
        <ul>
          <li>C'est / Ce n'est pas grave…</li>
          Ne prononcez pas le n ! Gardez la langue en bas.<br>
          un créatio<s>n</s> utile.
          <li>Avec la liaison</li>
          Prononez le n dans la syllabe de la liaison.<br>
          On est coincés ! = O<s>n</s>est-coin-cés !
        </ul>
      </v-card-text>
    </v-card>

    <h3>③ Mettez-y le ton !</h3>
    <ol type="a">
      <li>Écoutez. Que se passe-t-il ? Comment <Tooltip label="反応する">réagit</Tooltip> le jeune homme ?</li>
      <li><Tooltip label="あなたの番です">À votre tour</Tooltip>, exprimez votre colère en une phrase.</li>
      Je suis fou de <Tooltip label="激怒">rage</Tooltip> !!!
    </ol>
    <v-card>
      <v-card-text  class="blue--text text--darken-4">
        <b>Quand on est en colère,</b> la voix monte très haut<br>
        sur la dernière syllabe du groupe de mots.
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title class="pink--text text--darken-4">
        <v-spacer/><Tooltip label="あなた次第">C'EST À VOUS</Tooltip> !
      </v-card-title>
      <v-card-text>
        <ul>
          <li>Formez des groupes de trois et <Tooltip label="定義する">définissez</Tooltip> une situation problématique.</li>
          <p class="grey--text text: darken-1">Example : Vous avez invité votre chef et sa femme à dîner, mais vous vous êtes trompé de soir, et rien n'est prêt à leur arrivée.<br>Vous avez un exposé à faire mais vous avez oublié vos notes à la bibliothèque…</p>
          <li>Distribuez les rôles.</li>
          <p class="grey--text text: darken-1">Example : <Tooltip label="臆病">le peureux</Tooltip>, le raisonnable et le colérique.</p>
          <p>Puis réfléchissez au <Tooltip label="展開">déroulement</Tooltip> de la scène : vous analysez la situation et imaginez des solutions pour résoudre le problème.</p>
          <li>Jouez la scène !</li>
        </ul>
      </v-card-text>
    </v-card>

    <h1 class="purple--text text--darken-4  mt-5">Écrire un essai <Tooltip label="論証">argumentatif</Tooltip></h1>
    <h2 class=" mt-5"><Tooltip label="進化">Évolution </Tooltip>ou réveolution ?</h2>
    <p class=" mt-5">Le mot de « réveolution » <Tooltip label="使われる">s'emploie</Tooltip> facilement aujoud'hui, dès qu'il y a un changement ou une petite évolution.
      <Tooltip label="実際は">En réalité</Tooltip>, les vraies révolutions sont rares. 
      Quand peut-on parler de révolution et quand <Tooltip label="[…]に相当する、を意味する">s'agit-il</Tooltip> d'une simple évolution ?
      Petit panorama des grands <Tooltip label="出来事">événements</Tooltip> de notre Histoire.
    </p>
    <p>Intéressons-nous <Tooltip label="まず最初に">tout d'abord</Tooltip> à l'invention de l'<Tooltip label="印刷術">imprimerie</Tooltip>. 
      <Tooltip label="[…]によれば">Selon</Tooltip> Victor Hugo, <Tooltip label="[…]にすぎない">ce n'est là que</Tooltip> « le plus grand événement de l'Histoire »…
      <Tooltip label="言い換えれば、つまり">Autrement dit</Tooltip>, un événement, pas une révolution ! 
      Elle a <Tooltip label="にもかかわらず">pourtant</Tooltip> permis <Tooltip label="[…]以後">à partir de</Tooltip> la Renaissance une très large <Tooltip label="普及、流布、伝搬">diffusion</Tooltip> du savoir et a également facilité sa <Tooltip label="保存、保管、維持">conservation</Tooltip>. 
      <Tooltip label="したがって">Ainsi</Tooltip>, elle représente un progrès <Tooltip label="類まれな">exceptionnel</Tooltip>.</p>
    <p>Elle a <Tooltip label="にもかかわらず">pourtant</Tooltip> permis <Tooltip label="[…]以後">à partir de</Tooltip> la Renaissance une très large <Tooltip label="普及、流布、伝搬">diffusion</Tooltip> du savoir et a également facilité sa <Tooltip label="保存、保管、維持">conservation</Tooltip>. 
      <Tooltip label="[…は]どうでしょう">Qu'en est-il de</Tooltip> la révolution industrielle ?
      L'expression indique tout de suite de quoi il s'agit. 
      En effet, <Tooltip label="移行">le passage</Tooltip> à la fin du ⅩⅤⅢ<sup>e</sup> siècle à une société industrielle, <Tooltip label="一方">alors que</Tooltip> l'agriculture dominait depuis <Tooltip label="何百年も">des siècles</Tooltip>, 
      a <Tooltip label="促進する">favorisé</Tooltip> le développement de nombreux pays et <Tooltip label="引き起こす">a causé</Tooltip> un <Tooltip label="激動">bouleversement</Tooltip> profond et <Tooltip label="安定した">durable</Tooltip> des sociétés <Tooltip label="西洋の">occidentales</Tooltip>.
      On peut <Tooltip label="その結果">par conséquent</Tooltip> bien parler de révolution.
    </p>
    <p><Tooltip label="最後に">Enfin</Tooltip>, <Tooltip label="もっと">beaucoup plus</Tooltip> <Tooltip label="近年">récemment</Tooltip>, au début du développement des neurosciences, 
    les <Tooltip label="科学者">scientifiques</Tooltip> ont véritablement <Tooltip label="信じる">cru</Tooltip> que les nouvelles connaissances <Tooltip label="[…に]関する">sur</Tooltip> l'homme allaient révolutionner la médecine, en <Tooltip label="精神医学">psychiatrie</Tooltip> par exemple. 
    <Tooltip label="にもかかわらず">Néanmoins</Tooltip>, les <Tooltip label="失望">déceptions</Tooltip> ont suivi l'<Tooltip label="熱狂">enthousiasme</Tooltip> des débuts <Tooltip label="[…]なので">puisque de</Tooltip> nombreuses questions <Tooltip label="[…の]ままである、[…の状態に]とどまる">demeurent</Tooltip> sans réponse.
    Mais n'est-il pas normal pour toutes les révolutions de passer par des moments d'enthousiasme, <Tooltip label="次に">puis</Tooltip> de déception?</p>
    <p><Tooltip label="結論として">Pour conclure</Tooltip>, <Tooltip label="ほんの少し">peu</Tooltip> d'événements <Tooltip label="値する">méritent</Tooltip> d'être <Tooltip label="資格を備えた">qualifiés</Tooltip> de « révolution », mais <Tooltip label="こちら">celles-ci</Tooltip> existent <Tooltip label="まさに、本当に">bel et bien</Tooltip>.
    <Tooltip label="[…のケースが]そうである、あてはまる">Est-ce le cas</Tooltip> pour le big bang <Tooltip label="データ">numérique</Tooltip>?
    Aujourd'hui, nous sommes dans la périod d'enthousiasme.
    Mais durera-t-elle ?</p>
    <p><sub>D'après Françoise Pétry, « Évolution ou révolution ? », Pour la Science, novembre 2013.</sub></p>

    <h2 class=" mt-5">1 <Tooltip label="またたく間に">En un clin d'œil</Tooltip> !</h2>
    <ol type="a">
      <li>Lisez le titre de l'article. Pour vous, quelle serait la différence entre une évolution et une révolution ?</li>
      <li>Quelles sont les différentes parties de ce texte ?</li>
    </ol>

    <h2 class=" mt-5">2 Posez-vous les bonnes questions !</h2>
    Lisez le texte.
    <ol type="a">
      <li>Quelle question pose l'auteur ? Quelle réponse propose-t-elle ?</li>
      <li>Combien d'événements analyse-t-elle ? De quoi s'agit-il ?</li>
      <li>Comment est construit le paragraphe qui présente le troisième événement ? Nommez ses différentes étapes.</li>
    </ol>

    <h2 class=" mt-5">3 C'est dans la boîte !</h2>
    Complétez la boîte à outils en retrouvant les informations dans le texte.

    <v-card class=" mt-5">
      <v-card-title class="blue--text text--darken-4">
        <v-spacer/>BOIÎTE À OUTILS
      </v-card-title>
      <v-card-text>
        <h2 class="blue--text text--darken-4">Écrire un essai argumentatif</h2>
        <div class="blue--text text--darken-4 mt-5">Introduire</div>
        <ul>
          <li>Le thème : …</li>
          <li>La question posée : …</li>
        </ul>
        <div class="blue--text text--darken-4 mt-5">Développer son argumentation</div>
        <ul>
          <li><Tooltip label="つなぐ">Enchaîner</Tooltip> des idées : …</li>
          <li><Tooltip label="表現する">Exprimer</Tooltip> des <Tooltip label="つながり">liens</Tooltip> logiques :
            <ul style="list-style: none;">
              <li>la cause : …</li>
              <li>la conséquence : …</li>
              <li>l'opposition : …</li>
              <li>un paradoxe : …</li>
            </ul>
          </li>
          <li>Donner un exemple, <Tooltip label="引用する">citer</Tooltip> quelqu'un : …</li>
          <li><Tooltip label="作り直す、再形成する、再編する">Reformuler</Tooltip> une idée : …</li>
        </ul>
        <div class="blue--text text--darken-4 mt-5"><Tooltip label="締めくくる、結論を引き出す">Conclure</Tooltip></div>
        <ul>
          <li>Une phrase pour <Tooltip label="要約">résumer</Tooltip> : …</li>
          <li>Une ouverture : …</li>
        </ul>
      </v-card-text>
    </v-card>

    <h2>4 Du tac au tac !</h2>
    <ol type="a">
      <li></li>
      <li></li>
    </ol>

  </div>
</template>

<style>
</style>

<script>
import Tooltip from '@/components/Tooltip'
export default {
  name: 'Home',
  components: {Tooltip},
}
</script>
