export default {
  routes: [
    {
      path: '/lapetitefadette/contents',
      name: 'LaPetiteFadette_Contents',
      component: () => import(/* webpackChunkName: "about" */ '@/views/La_petite_Fadette/Contents.vue')
    },
    {
      path: '/lapetitefadette/chapitre01',
      name: 'LaPetiteFadette_Chapitre01',
      component: () => import(/* webpackChunkName: "about" */ '@/views/La_petite_Fadette/chapitre01.vue')
    }
  ]
}