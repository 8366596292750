<template>
  <v-tooltip top color="darkgrey">
    <template v-slot:activator="{ on, attrs }">
      <span
        v-bind="attrs"
        v-on="on"
        class="dottedunderline"
      >
      <slot/>
      </span>
    </template>
    <span>{{label}}</span>
  </v-tooltip>
</template>

<style>
  .dottedunderline{
    border-bottom: dotted 2px lightgrey;
  }
</style>

<script>
export default {
  props: ['label']
}
</script>