<template>
  <v-app id="app">
    <Navbar />
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.home{
  text-align: left;
}
.cahier{
  text-align: left;
}
.grammaire{
  text-align: left;
}
p {
  text-indent: 0.5em;
}

</style>

<script>
import Navbar from '@/components/Navbar'
export default {
  components: { Navbar },
}
</script>