import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import OliverTwist from '@/views/oliver_twist/routes.js'
import LaPetiteFadette from '@/views/La_petite_Fadette/routes.js'
import SOUVENIRS_ENTOMOLOGIQUES_Livre_I from '@/views/SOUVENIRS ENTOMOLOGIQUES Livre I/routes.js'
//import CapitaletIdéologie from '@/views/Capital_et_idéologie/routes.js'
import SoftwareEngineeringatGoogle from '@/views/SoftwareEngineeringatGoogle/routes.js'
import The_formation_of_vegetable_mould from '@/views/The formation of vegetable mould/routes.js'
import Paulgraham from '@/views/Paulgraham/routes.js'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/cahier',
    name: 'Cahier',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Cahier.vue')
  },
  {
    path: '/grammaire',
    name: 'Grammaire',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Grammaire.vue')
  },
  ...OliverTwist.routes,
  ...LaPetiteFadette.routes,
//  ...CapitaletIdéologie.routes,
  ...SoftwareEngineeringatGoogle.routes,
  ...SOUVENIRS_ENTOMOLOGIQUES_Livre_I.routes,
  ...The_formation_of_vegetable_mould.routes,
  ...Paulgraham.routes,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
