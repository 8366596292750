export default {
  routes: [
    {
      path: '/The formation of vegetable mould/contents',
      name: 'The_formation_of_vegetable_mould_Contents',
      component: () => import(/* webpackChunkName: "about" */ '@/views/The formation of vegetable mould/Contents.vue')
    },
    {
      path: '/The formation of vegetable mould/INTRODUCTION',
      name: 'The_formation_of_vegetable_mould_INTRODUCTION',
      component: () => import(/* webpackChunkName: "about" */ '@/views/The formation of vegetable mould/INTRODUCTION.vue')
    },
    {
      path: '/The formation of vegetable mould/CHAPTER I',
      name: 'The_formation_of_vegetable_mould_CHAPTER_I',
      component: () => import(/* webpackChunkName: "about" */ '@/views/The formation of vegetable mould/CHAPTER I.vue')
    },
  ]
}