export default {
  routes: [
    {
      path: '/SoftwareEngineeringatGoogle/contents',
      name: 'SoftwareEngineeringatGoogle_Contents',
      component: () => import(/* webpackChunkName: "about" */ '@/views/SoftwareEngineeringatGoogle/Contents.vue')
    },
    {
      path: '/SoftwareEngineeringatGoogle/chapter01',
      name: 'SoftwareEngineeringatGoogle_Chapter01',
      component: () => import(/* webpackChunkName: "about" */ '@/views/SoftwareEngineeringatGoogle/chapter01.vue')
    },
    {
      path: '/SoftwareEngineeringatGoogle/chapter02',
      name: 'SoftwareEngineeringatGoogle_Chapter02',
      component: () => import(/* webpackChunkName: "about" */ '@/views/SoftwareEngineeringatGoogle/chapter02.vue')
    },  
    {
      path: '/SoftwareEngineeringatGoogle/chapter03',
      name: 'SoftwareEngineeringatGoogle_Chapter03',
      component: () => import(/* webpackChunkName: "about" */ '@/views/SoftwareEngineeringatGoogle/chapter03.vue')
    },
    {
      path: '/SoftwareEngineeringatGoogle/chapter19',
      name: 'SoftwareEngineeringatGoogle_Chapter19',
      component: () => import(/* webpackChunkName: "about" */ '@/views/SoftwareEngineeringatGoogle/chapter19.vue')
    },
    {
      path: '/SoftwareEngineeringatGoogle/chapter20',
      name: 'SoftwareEngineeringatGoogle_Chapter20',
      component: () => import(/* webpackChunkName: "about" */ '@/views/SoftwareEngineeringatGoogle/chapter20.vue')
    },
  ]
}