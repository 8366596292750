export default {
  routes: [
    {
      path: '/olivertwist/contents',
      name: 'OliverTwist_Contents',
      component: () => import(/* webpackChunkName: "about" */ '@/views/oliver_twist/Contents.vue')
    },
    {
      path: '/olivertwist/chapter01',
      name: 'OliverTwist_Chapter01',
      component: () => import(/* webpackChunkName: "about" */ '@/views/oliver_twist/chapter01.vue')
    },  
  ]
}