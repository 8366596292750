export default {
  routes: [
    {
      path: '/SOUVENIRS ENTOMOLOGIQUES Livre I/contents',
      name: 'SOUVENIRS_ENTOMOLOGIQUES_Livre_I_Contents',
      component: () => import(/* webpackChunkName: "about" */ '@/views/SOUVENIRS ENTOMOLOGIQUES Livre I/Contents.vue')
    },
    {
      path: '/SOUVENIRS ENTOMOLOGIQUES Livre I/CHAPITRE I',
      name: 'SOUVENIRS_ENTOMOLOGIQUES_Livre_I_Chapter_I',
      component: () => import(/* webpackChunkName: "about" */ '@/views/SOUVENIRS ENTOMOLOGIQUES Livre I/CHAPITRE I.vue')
    },
  ]
}